import React from "react";
import { FaTelegramPlane } from "react-icons/fa"; // Import Telegram Icon

const App = () => {

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = '/cryptowolf.apk'; // Path to the APK in the public folder
    link.setAttribute('download', 'cryptowolf.apk'); // Filename for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="bg-black text-white min-h-screen flex flex-col font-sans">
      {/* Navbar */}
      <header className="flex justify-between items-center px-6 py-4">
        {/* Logo */}
        <img
          src="./logo.png"
          alt="Logo"
          className="h-20 sm:h-20 md:h-20 w-auto"
        />

        {/* Telegram Group Message */}
        <div className="flex items-center space-x-2">
          <FaTelegramPlane className="text-teleblue text-3xl" />
          <a
            href="https://t.me/cryptowolf112"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gold font-bold hover:underline"
          >
            Join Telegram Group
          </a>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex flex-col md:flex-row items-center justify-between flex-grow px-6 py-12 gap-12">
        {/* Text Section */}
        <div className="text-center md:text-left md:w-3/5 max-w-full w-full flex flex-col items-center justify-center">
          <h1 className="text-3xl md:text-4xl font-bold text-gold mb-12">
            MAKE MONEY WITH <span className="text-white">Crypto Wolf</span>
          </h1>

          <h3 className="text-2xl md:text-3xl font-bold mb-12">
            WE HELP YOU TO BOOST YOUT <span className="text-gold">USDT</span> RETURNS
          </h3>
          <p className="text-lg md:text-xl mb-8 text-center">
            Download our mobile app and elevate your experience. Stay connected,
            stay productive, stay ahead and keeping earning!!!!!!.
          </p>
          {/* Call-to-Action Buttons */}
          <div className="flex flex-col sm:flex-row gap-4 justify-center md:justify-start">
            {/* <button className="bg-gold text-black px-6 py-3 rounded-md text-lg hover:bg-white hover:text-black transition">
              Download for iOS
            </button> */}
            <button className="bg-gold text-black px-6 py-3 rounded-md text-lg hover:bg-white hover:text-black transition" onClick={handleDownload}>
              Download for Android
            </button>
          </div>
        </div>


        {/* App Preview Section */}
        <div className="relative flex flex-wrap justify-center items-center gap-6 md:gap-8 md:w-2/5">
          {/* First Preview */}
          <img
            src="/screenshot1.png"
            alt="App Preview"
            className="rounded-lg w-40 sm:w-60 md:w-72 z-10"
          />
          {/* Second Preview */}
          {/* <img
            src="/screenshot2.png"
            alt="App Preview 2"
            className="rounded-lg  w-40 sm:w-60 md:w-72"
          /> */}
        </div>
      </main>

      <div className="text-center md:text-left md:w-5/5  w-full flex flex-col items-center justify-center">
        <h1 className="text-4xl md:text-5xl font-bold text-gold mb-12">
          More Friends <span className="text-white">More Reward Policy</span>
        </h1>
        <h1 className="text-4xl md:text-5xl font-bold text-white mb-12">
          Earn Big Commisions
        </h1>
        <p className="text-lg md:text-xl mb-8 text-center w-3/5">
          Invaite your friends and familys to trade with <span className="text-gold">Crypto Wolf</span> and enjoy generous commisions for upto Three levels of referrals as they get started
        </p>

        <p className="text-lg md:text-2xl mb-8 text-center w-3/5">
          The more people you refer, the more you <span className="text-gold text-4xl"> "Earn!!!!"</span>
        </p>
        {/* Call-to-Action Buttons */}
        <ul>
          <li>
            <p className="text-lg md:text-xl mb-8 text-center">
              The Level 1 : Earns <span className="text-gold text-3xl"> 0.5% </span>Commisions
            </p>
          </li>
          <li>
            <p className="text-lg md:text-xl mb-8 text-center">
              The Level 2 : Earns <span className="text-gold text-3xl"> 0.3% </span>Commisions
            </p>
          </li>
          <li>
            <p className="text-lg md:text-xl mb-8 text-center">
              The Level 3 : Earns <span className="text-gold text-3xl"> 0.2% </span>Commisions
            </p>
          </li>
        </ul>

      </div>

      {/* Promotion Video Section */}
      <section className="px-6 py-12">
        <h2 className="text-2xl md:text-3xl font-bold text-gold text-center mb-6">
          Watch the Promo Video
        </h2>
        <div className="flex justify-center">
          <iframe
            className="w-full md:w-2/3 h-48 md:h-80 border-4 border-gold rounded-lg"
            src="https://www.youtube.com/embed/uPhVsXFeZpQ?si=R_qdAwOeACkZfglR"
            title="Promo Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </section>

      {/* Footer */}
      <footer className="text-center py-4 border-t border-gold">
        <p>&copy; {new Date().getFullYear()} Crypto Wolf. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default App;
